import './Obsah.css';
import uvod_1 from './pics/uvod_1.jpg';
import mapa from "./pics/mapa_2021.png";
import mapa_en from "./pics/mapa_new_en.png"
import CarouselWithMap from './Components_2/CarouselWithMap';
import Calendar from './Components_2/Calendar';
import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


function Obsah() {
    const {t, i18n} = useTranslation("global");

    const [kraje, setKraje] = useState([]);
    const [aktuality, setAktuality] = useState([]);
    const [partneri, setPartneri] = useState([]);
    const [zastita, setZastita] = useState([]);

    const [loadingAktuality, setLoadingAktuality] = useState(true);
    const [loadingKraje, setLoadingKraje] = useState(true);
    const [loadingPartneri, setLoadingPartneri] = useState(true);
    const [loadingZastita, setLoadingZastita] = useState(true);

    const [errorAktuality, setErrorAktuality] = useState(null);
    const [errorKraje, setErrorKraje] = useState(null);
    const [errorPartneri, setErrorPartneri] = useState(null);
    const [errorZastita, setErrorZastita] = useState(null);

    useEffect(() => { 
        fetch(process.env.REACT_APP_API_URL+`/api/kpbi-aktualities?sort[0]=id:desc&locale=${i18n.language}&pagination[limit]=2&populate=*`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAktuality(data.data);
                setLoadingAktuality(false);
                
            })
            .catch(error => {
                setErrorAktuality(error);
                setLoadingAktuality(false);
            });
    }, [i18n.language]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+`/api/kpbi-kraje-do-projektus?populate=*`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setKraje(data.data);
                setLoadingKraje(false);
                //console.log(data.data);
                
            })
            .catch(error => {
                setErrorKraje(error);
                setLoadingKraje(false);
            });
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/api/kpbi-partneri-projektus?populate=*')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPartneri(data.data);
                setLoadingPartneri(false);
                //console.log(data.data);
            })
            .catch(error => {
                setErrorPartneri(error);
                setLoadingPartneri(false);
            });
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/api/kpbi-zastita-nad-projektems?populate=*')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setZastita(data.data);
                setLoadingZastita(false);
                //console.log(data.data);
            })
            .catch(error => {
                setErrorZastita(error);
                setLoadingZastita(false);
            });
    }, []);

    if (loadingPartneri || loadingKraje || loadingZastita || loadingAktuality) return <div>Načítání...</div>;
    if (errorKraje || errorPartneri || errorZastita || errorAktuality) return <div>Chyba...</div>;
    return (
        <div>
            <div id="all_mapa">
                {i18n.language === 'en' ? (
                    <div id="main_mapa_en">
                        <div className="carousel-container">
                            <CarouselWithMap/>
                        </div>
                        <img src={mapa_en} alt="mapa" useMap="#image-map_en" className="obrazek_mapa"/>
                        <map name="image-map_en">
                            <area shape="poly" alt="For Kids and Students" title="For Kids and Students"
                                  coords="475,148,514,79,486,35,704,35,705,13,473,13,393,12,355,80,395,149"
                                  href="http://elearning.ecrime.cz/course/view.php?id=2" target="_blank"/>
                            <area shape="poly" alt="For Social Workers" title="For Social Workers"
                                  coords="389,149,348,216,3,220,3,196,253,193,232,151,270,84,349,82"
                                  href="http://elearning.ecrime.cz/course/view.php?id=4" target="_blank"/>
                            <area shape="poly" alt="For Parents" title="For Parents"
                                  coords="518,218,480,148,517,82,596,82,640,152,613,196,735,196,735,217"
                                  href="http://elearning.ecrime.cz/course/view.php?id=3" target="_blank"/>
                            <area shape="poly" alt="For Police Officers" title="For Police Officers"
                                  coords="394,289,633,289,634,266,490,264,512,222,472,150,395,153,355,218"
                                  href="http://elearning.ecrime.cz/course/view.php?id=5" target="_blank"/>
                            <area shape="poly" alt="For Teachers" title="For Teachers"
                                  coords="336,361,125,362,126,341,253,335,230,292,270,223,349,225,388,294,349,360"
                                  href="http://elearning.ecrime.cz/course/view.php?id=6" target="_blank"/>
                            <area shape="poly" alt="For Senior Citizens" title="For Senior Citizens"
                                  coords="395,294,352,364,393,433,627,437,628,412,493,406,513,366,472,295"
                                  href="http://elearning.ecrime.cz/course/view.php?id=17" target="_blank"/>
                        </map>
                    </div>
                ) : (
                    <div id="main_mapa">
                        <div className="carousel-container">
                            <CarouselWithMap/>
                        </div>
                        <img src={mapa} alt="mapa" useMap="#image-map" className="obrazek_mapa"/>
                        <map name="image-map">
                            <area target="_blank" alt="videa" title="videa"
                                  href="https://www.youtube.com/channel/UCRaYk6bGcGYoLw_RBNACrDA"
                                  coords="429,196,463,137,427,77,356,76,323,136,359,198" shape="poly"/>
                            <area target="_blank" alt="elearning" title="elearning" href="https://elearning.ecrime.cz/"
                                  coords="548,127,580,68,546,8,475,7,442,68,477,129" shape="poly"/>
                            <area target="_blank" alt="socialnisite" title="socialnisite"
                                  href="https://www.facebook.com/kpbicz"
                                  coords="596,76,562,137,596,197,666,197,701,138,667,76" shape="poly"/>
                            <area target="_blank" alt="clanky" title="clanky" href="/vim-kam-klikam"
                                  coords="581,208,547,267,476,267,442,208,476,145,546,146" shape="poly"/>
                            <area target="_blank" alt="skoleni" title="skoleni" href="/skoleni"
                                  coords="716,146,680,206,717,266,786,265,820,206,785,146" shape="poly"/>
                            <area target="_blank" alt="tutorialy" title="tutorialy"
                                  href="https://www.youtube.com/watch?v=pZVL2h6NmzQ&list=PLrT6AA3DsRDBxuGO6LNDAs32U5ty2E3Ir"
                                  coords="426,214,462,275,428,334,358,334,324,275,357,213" shape="poly"/>
                            <area target="_blank" alt="pracovnilisty" title="pracovnilisty" href="/pracovni-listy"
                                  coords="595,213,561,273,597,334,667,333,700,274,666,214" shape="poly"/>
                            <area target="_blank" alt="souteznikviz" title="souteznikviz"
                                  href="https://elearning.ecrime.cz/course/view.php?id=50"
                                  coords="477,282,443,344,477,403,547,403,582,344,547,283" shape="poly"/>
                        </map>
                    </div>
                )}

            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("aktualne")}</h3>
                            <hr className="green-line"/>
                        </div>
                        {
                            aktuality.map((aktualita) => (
                                <div className="col-md-12 mb-4 card-">
                                    <Link to={`/aktualne-detail/${aktualita.id}`} className="odkaz_projekt">
                                        <img
                                            src={
                                                aktualita.attributes.coverImage && aktualita.attributes.coverImage.data
                                                    ? process.env.REACT_APP_API_URL + aktualita.attributes.coverImage.data[0].attributes.url
                                                    : uvod_1
                                            }
                                            alt={aktualita.attributes.name}
                                            className="card-img-top"
                                            style={{objectFit: 'cover', width: '100%', maxHeight: '100px'}}
                                        />
                                        <div className="card-body">
                                            <p className="card-title projekt_odkaz">{aktualita.attributes.name}</p>
                                            <p className="vice">{t("vice")}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                        <Link to="/aktualne" className="btn btn-success btn-akt">{t("aktualne")}</Link>
                        <div className="col-lg-12">
                            <h3>{t("kraje_zapojene_do_projektu")}</h3>
                            <hr className="green-line"/>
                        </div>
                        {
                            kraje.map((kraj) => (
                                <div className="col-lg-4 container content">
                                    {kraj && kraj.attributes.logo && kraj.attributes.logo.data &&
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}${kraj.attributes.logo.data.attributes.url}`}
                                            alt="kraj"
                                            className={`img-fluid ${kraj.id === 14 || kraj.id === 28 ? 'small-image' : 'img_side'}`}
                                />
                            }
                            <br></br>
                            </div>
                        ))
                        }
                    <div className="col-lg-12">
                        <h3>{t("partneri_projektu")}</h3>
                        <hr className="green-line"/>
                    </div>
                    {
                            partneri.map((partner) => (
                            <div className="col-lg-4">
                            {partner && partner.attributes.logo && partner.attributes.logo.data &&
                                <img
                                    src={`${process.env.REACT_APP_API_URL}${partner.attributes.logo.data.attributes.url}`}
                                    alt={partner.attributes.name}
                                    className={`img-fluid ${partner.id === 6 || partner.id === 4 ? 'small-image' : 'img_side'}`}
                                />
                            }
                            <br></br>
                            </div>
                        ))
                        }
                    <div className="col-lg-12">
                        <h3>{t("zastita_nad_projektem")}</h3>
                        <hr className="green-line"/>
                    </div>
                    {
                            zastita.map((zastit) => (
                            <div className="col-lg-4">
                            {zastit && zastit.attributes.logo && zastit.attributes.logo.data &&
                                <img
                                    src={`${process.env.REACT_APP_API_URL}${zastit.attributes.logo.data.attributes.url}`}
                                    alt={zastit.attributes.name}
                                    className="img-fluid images_"
                                />
                            }
                            <br></br>
                            </div>
                        ))
                        }
                </div>
            </div>
            <div className="col-lg-3">
                <div className="fb">
                <div className="fb-page fb_iframe_widget" data-href="https://www.facebook.com/kpbicz" data-width="280" data-height="500" data-hide-cover="false" data-show-facepile="true"
                     data-show-posts="true">
                    <span className="fb_1"><iframe name="f940e95f9cc94" width="280px" height="500px" data-testid="fb:page Facebook Social Plugin" title="fb:page Facebook Social Plugin" allow="encrypted-media"
                    src="https://www.facebook.com/v2.3/plugins/page.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df7452084e987c%26domain%3Dwww.kpbi.cz%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.kpbi.cz%252Ffa1cbfe27e0f44%26relation%3Dparent.parent&amp;container_width=270&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fkpbicz&amp;locale=cs_CZ&amp;sdk=joey&amp;show_facepile=true&amp;show_posts=true&amp;width=280" className="fb_2">
                    </iframe></span></div>
            </div>
                <hr className="green-line"/>
            <div className="ig">
                <iframe width="280" height="500" src="https://www.instagram.com/p/CRx_veQD1Iv/embed" title="ig_frame"></iframe>
            </div>
                <Calendar/>
            </div>


        </div>
        </div>
    );
}

export default Obsah;